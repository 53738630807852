<template>
    <div>
        页面开发中
    </div>
</template>

<script>
export default {
    data() {
        return {
            childList: [
                '0~1000方',
                '0~1000方',
                '0~1000方',
                '0~1000方',
                '0~1000方',
                '0~1000方',
                '0~1000方',
                '0~1000方',
                '0~1000方',
                '0~1000方',
            ],
        };
    },
    mounted() {
        this.$emit('listLength', this.childList);
    },
};

</script>
<style lang='stylus'>

</style>